import type {
	CanFunction,
	CanParameters,
} from "@app/modules/authorization/types";
import { SearchParamsContext } from "@app/modules/routes/search-params";
import type {
	LayoutSettings,
	RouteConfig,
	RouteLayoutName,
	RoutesByPath,
} from "@app/modules/routes/types";
import { DEFAULT_ROUTE_LAYOUT } from "@app/modules/routes/types";
import type { SearchObject } from "@app/modules/routes/utils";
import { generateFullPaths, stringifySearch } from "@app/modules/routes/utils";
import type { State } from "history";
import { useContext } from "react";
import type { RouteObject } from "react-router";
import {
	generatePath,
	matchRoutes,
	useLocation,
	// eslint-disable-next-line no-restricted-imports
	useNavigate as useReactRouterNavigate,
} from "react-router-dom";

export const routesByName = {
	"customer-data": {
		path: "customer-data",
		params: undefined,
		hasShortcut: false,
	},
	"customer-data.customers": {
		path: "customers",
		params: undefined,
		hasShortcut: true,
	},
	// route names with "." inherit the base path of their parent.
	// in this case the final path will be "/customer/new"
	"customer-data.customers.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	// We specify a dummy object as params for routes that need it.
	// We then leverage this below to extract the type of the params and are able
	// to provide superb type checking and completion in the `getLinkToProp` function.
	"customer-data.customers.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"customer-data.customers.id.temporal-sales-teams": {
		path: "temporal-sales-teams",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"customer-data.customer-groups": {
		path: "customer-groups",
		params: undefined,
		hasShortcut: false,
	},
	"customer-data.customer-groups.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"customer-data.customer-groups.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"customer-data.sales-teams": {
		path: "sales-teams",
		params: undefined,
		hasShortcut: false,
	},
	"customer-data.sales-teams.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"customer-data.sales-teams.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"customer-data.tours": {
		path: "tours",
		params: undefined,
		hasShortcut: false,
	},
	"customer-data.tours.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"customer-data.tours.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	dashboard: { path: "", params: undefined, hasShortcut: false },
	"product-data": {
		path: "product-data",
		params: undefined,
		hasShortcut: false,
	},
	"product-data.products": {
		path: "products",
		params: undefined,
		hasShortcut: false,
	},
	"product-data.products.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"product-data.products.id.temporal": {
		path: "temporal",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"product-data.products.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"product-data.hierarchical-labels": {
		path: "hierarchical-labels",
		params: undefined,
		hasShortcut: false,
	},
	"product-data.hierarchical-labels.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"product-data.hierarchical-labels.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"product-data.groups": {
		path: "product-groups",
		params: undefined,
		hasShortcut: false,
	},
	"product-data.groups.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"product-data.groups.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"misc-data.stock-locations": {
		path: "stock-locations",
		params: undefined,
		hasShortcut: false,
	},
	"misc-data.stock-locations.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"misc-data.stock-locations.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	suppliers: { path: "suppliers", params: undefined, hasShortcut: false },
	"suppliers.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"suppliers.new": { path: "new", params: undefined, hasShortcut: false },
	purchasing: { path: "purchasing", params: undefined, hasShortcut: false },
	"purchasing.planning": {
		path: "planning",
		params: undefined,
		hasShortcut: false,
	},
	"purchasing.orders": {
		path: "orders",
		params: undefined,
		hasShortcut: false,
	},
	"purchasing.orders.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"purchasing.framework-agreements": {
		path: "framework-agreements",
		params: undefined,
		hasShortcut: false,
	},
	"purchasing.framework-agreements.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"purchasing.returns": {
		path: "returns",
		params: undefined,
		hasShortcut: false,
	},
	sales: { path: "sales", params: undefined, hasShortcut: false },
	"sales.customer-orders": {
		path: "customer-orders",
		params: undefined,
		hasShortcut: false,
	},
	"sales.customer-orders.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"sales.internal-orders": {
		path: "internal-orders",
		params: undefined,
		hasShortcut: false,
	},
	"sales.internal-orders.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"sales.returns": {
		path: "returns",
		params: undefined,
		hasShortcut: false,
	},
	dismantling: { path: "dismantling", params: undefined, hasShortcut: false },
	"dismantling.plan": {
		path: "plan",
		params: undefined,
		hasShortcut: false,
	},
	"dismantling.printing": {
		path: "printing",
		params: undefined,
		hasShortcut: false,
	},
	operations: {
		path: "operations",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.dismantling-goods-receipt": {
		path: "dismantling-goods-receipt",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.hierarchical-goods-receipt": {
		path: "hierarchical-goods-receipt",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.supplier-order-goods-receipt": {
		path: "supplier-order-goods-receipt",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.supplier-order-goods-receipt.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
		layout: "processing",
	},
	"operations.customer-order-returns": {
		path: "customer-order-returns",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.customer-order-returns.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
		layout: "processing",
	},
	"operations.supplier-order-returns": {
		path: "supplier-order-returns",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.supplier-order-returns.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
		layout: "processing",
	},
	"operations.picking": {
		path: "picking",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.picking.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
		layout: "processing",
	},
	"operations.price-labelling": {
		path: "price-labelling",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.rolling-inventory": {
		path: "rolling-inventory",
		params: undefined,
		hasShortcut: false,
		layout: "touch",
	},
	"operations.rolling-inventory.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
		layout: "processing",
	},
	invoicing: { path: "invoicing", params: undefined, hasShortcut: false },
	"invoicing.invoices": {
		path: "invoices",
		params: undefined,
		hasShortcut: false,
	},
	"invoicing.invoices.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"invoicing.credit-notes": {
		path: "credit-notes",
		params: undefined,
		hasShortcut: false,
	},
	"invoicing.credit-notes.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	stock: { path: "stock", params: undefined, hasShortcut: false },
	"stock.inventory": {
		path: "inventory",
		params: undefined,
		hasShortcut: false,
	},
	"stock.transactions": {
		path: "transactions",
		params: undefined,
		hasShortcut: false,
	},
	"stock.containers": {
		path: "containers",
		params: undefined,
		hasShortcut: false,
	},
	"stock.journal-entries": {
		path: "journal-entries",
		params: undefined,
		hasShortcut: false,
	},
	"stock.journal-entries.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	pricing: { path: "pricing", params: undefined, hasShortcut: false },
	"pricing.cutting-patterns": {
		path: "cutting-patterns",
		params: undefined,
		hasShortcut: false,
	},
	"pricing.cutting-patterns.pg-id": {
		path: ":pgId",
		params: { pgId: "" as string },
		hasShortcut: false,
	},
	"pricing.dismantling": {
		path: "dismantling",
		params: undefined,
		hasShortcut: false,
	},
	"pricing.dismantling.id": {
		path: ":productGroupId",
		params: { productGroupId: "" as string },
		hasShortcut: false,
	},
	"pricing.price-lists": {
		path: "price-lists",
		params: undefined,
		hasShortcut: false,
	},
	"pricing.price-lists.details": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"pricing.price-lists.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	settings: { path: "settings", params: undefined, hasShortcut: false },
	"settings.work-calendar": {
		path: "work-calendar",
		params: undefined,
		hasShortcut: false,
	},
	"settings.labels": { path: "labels", params: undefined, hasShortcut: false },
	"settings.scale-setup": {
		path: "hardware-setup",
		params: undefined,
		hasShortcut: false,
	},
	"settings.misc": { path: "misc", params: undefined, hasShortcut: false },
	"settings.workstations": {
		path: "workstations",
		params: undefined,
		hasShortcut: false,
	},
	"settings.workstations.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"settings.workstations.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"settings.hardware-devices": {
		path: "hardware-devices",
		params: undefined,
		hasShortcut: false,
	},
	"settings.hardware-devices.new": {
		path: "new",
		params: undefined,
		hasShortcut: false,
	},
	"settings.hardware-devices.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	// unused routes
	production: { path: "production", params: undefined, hasShortcut: false },
	performance: { path: "performance", params: undefined, hasShortcut: false },
	// internal routes
	styleguide: { path: "styleguide", params: undefined, hasShortcut: false },
	testing: { path: "testing", params: undefined, hasShortcut: false },
	"testing.graphql-errors": {
		path: "graphql-errors",
		params: undefined,
		hasShortcut: false,
	},
	debug: { path: "debug", params: undefined, hasShortcut: false },
	tbd: { path: "tbd", params: undefined, hasShortcut: false },
	users: { path: "users", params: undefined, hasShortcut: false },
	"users.id": {
		path: ":id",
		params: { id: "" as string },
		hasShortcut: false,
	},
	"users.new": { path: "new", params: undefined, hasShortcut: false },
	"misc-data": { path: "misc-data", params: undefined, hasShortcut: false },
	notifications: {
		path: "notifications",
		params: undefined,
		hasShortcut: false,
	},
} satisfies RoutesByPath;

export type RoutesConfig = typeof routesByName;
export type RouteName = keyof RoutesConfig;
export type ParamsOf<T extends RouteName> = RoutesConfig[T]["params"];

export const fullPathsByName = generateFullPaths(routesByName);

export const routeNames = Object.keys(routesByName) as RouteName[];

export const authParamsByName: Dictionary<RouteName, CanParameters> = {
	production: ["use", "Production"],
	dismantling: ["use", "DismantlingPlan"],
	"dismantling.printing": ["use", "DismantlingPrinting"],
	"purchasing.planning": ["use", "DemandPlanning"],
	"pricing.dismantling": ["use", "Pricing"],
	"pricing.price-lists": ["use", "Pricing"],
	"pricing.cutting-patterns": ["use", "Pricing"],
	users: ["create", "users"],
	"users.id": ["create", "users"],
	"users.new": ["create", "users"],
};

export function getRouteLayoutName(name: RouteName): RouteLayoutName {
	return (routesByName[name] as RouteConfig).layout ?? DEFAULT_ROUTE_LAYOUT;
}

export function canAccessRoute(can: CanFunction, name: RouteName) {
	const params = authParamsByName[name];
	return !params || (params && can(...params));
}

export function getHref<T extends RouteName>(
	name: RouteName,
	params?: RoutesConfig[T]["params"],
) {
	const path = fullPathsByName[name];
	if (!params) {
		return path;
	}
	return generatePath(path, params);
}

export type RouteParams<T extends RouteName> = ParamsOf<T> extends undefined
	? [undefined?]
	: [ParamsOf<T>];

export function getTypedHref<T extends RouteName>(
	name: T,
	...args: RouteParams<T>
) {
	const path = fullPathsByName[name];
	const params = args[0];
	if (!params) {
		return path;
	}
	return getHref(name, args[0]);
}

export const to = getTypedHref;

export function appendSearch(href: string, search: SearchObject) {
	return `${href}${stringifySearch(search)}`;
}

type NamedRouteObject = RouteObject & { name?: string };

export function isRouteName(name: string): name is RouteName {
	return routesByName[name as RouteName] !== undefined;
}

// todo: this may need a better name :)
const allNamedRoutes: NamedRouteObject[] = Object.entries(fullPathsByName).map(
	([name, path]) => ({
		path,
		element: null,
		name,
		caseSensitive: false,
	}),
);

export function useActiveRouteName(): RouteName | undefined {
	const location = useLocation();
	const matches = matchRoutes(allNamedRoutes, location);
	const name = (matches?.[0]?.route as NamedRouteObject | undefined)?.name;
	if (name && isRouteName(name)) {
		return name;
	}
	return undefined;
}

export function useActiveRouteLayout(): LayoutSettings {
	const name = useActiveRouteName();
	const layout = name ? getRouteLayoutName(name) : DEFAULT_ROUTE_LAYOUT;
	return layoutSettings[layout];
}

const layoutSettings: Record<RouteLayoutName, LayoutSettings> = {
	desktop: {
		mainArea: {
			hasPadding: true,
		},
		header: {
			isCompact: false,
		},
		navigation: {
			isVisible: true,
		},
		cardDrawer: {
			isVisible: true,
		},
	},
	touch: {
		mainArea: {
			hasPadding: false,
		},
		header: {
			isCompact: true,
		},
		navigation: {
			isVisible: true,
		},
		cardDrawer: {
			isVisible: false,
		},
	},
	processing: {
		mainArea: {
			hasPadding: false,
		},
		header: {
			isCompact: true,
		},
		navigation: {
			isVisible: false,
		},
		cardDrawer: {
			isVisible: false,
		},
	},
};

export function useNavigate() {
	const { params } = useContext(SearchParamsContext);
	const navigate = useReactRouterNavigate();
	return (
		href: string | number,
		options?: {
			replace?: boolean;
			state?: State;
			keepSearch?: boolean;
		},
	) => {
		if (typeof href === "number") {
			navigate(href);
		} else {
			const finalHref = options?.keepSearch ? appendSearch(href, params) : href;
			navigate(finalHref, options);
		}
	};
}
